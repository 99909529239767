import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-138149aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "textfield" }
const _hoisted_2 = {
  class: "textfield-label",
  for: "textfield"
}
const _hoisted_3 = ["value", "type", "autocomplete"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.placeholder), 1),
    _createElementVNode("input", {
      value: _ctx.modelValue,
      class: "textfield-input",
      name: "textfield",
      type: _ctx.type,
      autocomplete: !_ctx.autocomplete ? 'off' : '',
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.valueUpdated($event.target.value)))
    }, null, 40, _hoisted_3)
  ]))
}