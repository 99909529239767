import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2d5722d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "error-messages"
}
const _hoisted_2 = { class: "shake" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.firstErrorMessage)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.firstErrorMessage), 1)
      ]))
    : _createCommentVNode("", true)
}