import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-38bc6f88"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["is-opened"]
const _hoisted_2 = { class: "selected" }
const _hoisted_3 = {
  key: 0,
  class: "no-result"
}
const _hoisted_4 = ["selected", "onClick", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_scroll = _resolveComponent("ui-scroll")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "dropdown",
    class: "dropdown",
    onFocus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpen = true)),
    onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isOpen = false)),
    "is-opened": _ctx.isOpen,
    tabindex: "1"
  }, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.placeholder), 1),
    _createElementVNode("div", {
      class: _normalizeClass(`dropdown-menu ${_ctx.variant}`)
    }, [
      (_ctx.filters.length <= 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, "Nothing found"))
        : (_openBlock(), _createBlock(_component_ui_scroll, { key: 1 }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "menu-item",
                  selected: _ctx.isSelected(item[_ctx.itemValue]),
                  onClick: ($event: any) => (_ctx.onChangeHandler(item[_ctx.itemValue])),
                  title: item[_ctx.itemText]
                }, _toDisplayString(item[_ctx.itemText]), 9, _hoisted_4))
              }), 128))
            ]),
            _: 1
          }))
    ], 2),
    _createVNode(_component_font_awesome_icon, {
      class: _normalizeClass(`select-icon ${_ctx.variant}`),
      icon: ['fal', 'angle-down']
    }, null, 8, ["class"])
  ], 40, _hoisted_1))
}