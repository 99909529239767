
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TextField',
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
      required: true,
    },
    autocomplete: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    valueUpdated(value: string) {
      this.$emit('update:modelValue', value);
    },
  },
});
