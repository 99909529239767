
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UiErrors',
  props: {
    errorMessages: {
      type: Array,
    },
  },
  computed: {
    firstErrorMessage(): any {
      return this.errorMessages ? this.errorMessages[0] : '';
    },
  },
});
