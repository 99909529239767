
import { defineComponent } from 'vue';
import UiScroll from '@/components/form/UiScroll.vue';

export default defineComponent({
  name: 'Filter',
  props: {
    filters: {
      type: Array,
      required: true,
    },
    variant: {
      type: String,
      default: 'black',
    },
    placeholder: {
      type: String,
      required: true,
    },
    itemText: {
      type: String,
      default: 'title',
    },
    itemValue: {
      type: String,
      default: 'id',
    },
  },
  emits: ['option:selected'],
  components: {
    UiScroll,
  },
  data: () => ({
    isOpen: false as boolean,
    selectedOption: '' as string,
  }),
  methods: {
    isSelected(item: string): boolean {
      return item === this.selectedOption;
    },
    reset(): void {
      this.selectedOption = '';
      this.$emit('option:selected', '');
    },
    onChangeHandler(item: string): void {
      const target = this.$refs.dropdown as HTMLDivElement;
      target.blur();

      if (this.selectedOption === item) {
        this.selectedOption = '';
        this.$emit('option:selected', '');
      } else {
        this.selectedOption = item;
        this.$emit('option:selected', item);
      }
    },
  },
});
